import React from "react"
import MenuBar from "../layout/MenuBar"
import { createGlobalStyle } from "styled-components"
import Footer from "../layout/Footer"
import { Helmet } from "react-helmet"

const GlobalStyle = createGlobalStyle`
  html{
    scroll-behavior: smooth;
  }
  body {
    background-color: white;
    padding: 0px;
    margin: 0px;
    font-size: 1.2rem;
    line-height: 24px;
    font-family: Montserrat,open-sans,sans-serif;
  }
  a { cursor: pointer; }
  h1 {line-height: 1.2;}
  h2 {line-height: 1.4;}
  h3 {line-height: 1.4;}

  button { cursor: pointer;}
`

const GlobalStyles = props => {
  return (
    <div>
      <Helmet>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4632341699833963"
          crossorigin="anonymous"
        ></script>
      </Helmet>
      <GlobalStyle />
      <MenuBar />
      {props.children}
      <Footer />
    </div>
  )
}

export default GlobalStyles
