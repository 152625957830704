import React from "react"
import styled from "styled-components"

const FooterComponent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #2f2f2f;
  color: white;
  height: 100px;
  margin: 50px 0px 0px 0px;
  text-align: center;
  align-items: center;
  font-size: 15px;
`

const Footer = () => {
  return (
    <FooterComponent>
      <div>© Copyright 2019 Tallinnaan.fi </div>
      <div>Lisätietoa palvelusta</div>
      <div>Ota yhteyttä</div>
    </FooterComponent>
  )
}

export default Footer