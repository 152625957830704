import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FaBars } from "react-icons/fa"
import { device } from "../../utils/mediaQueries"
import LogoImage from "../images/LogoImage"

const NavBar = styled.div`
  grid-gap: 3px;
  color: black;
  font-weight: 700;
  font-size: 17px;
  align-content: -webkit-center;
  padding-bottom: 50px;
  padding-top: 30px;
  padding: 10px 4%;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid lightblue;
  @media ${device.tablet} {
    display: none;
  }
`

const NavBarMobile = styled.div`
  display: none;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
  color: white;
  max-height: 5000px;
  align-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 0px;
  }
`
const Icon = styled(FaBars)`
  color: #033a60;
`

const Dropdown = styled.div`
  float: left;
  overflow: visible;
  text-align: -webkit-center;
`
const Menulink = styled(Link)`
  text-decoration: none;
`

const DropdownButton = styled.button`
  display: block;
  border: 2px solid white;
  color: #3a3a3a;
  outline: none;
  padding: 7px;
  text-decoration: none;
  background-color: inherit;
  font-family: inherit;
  font-size: 17px;
  font-weight: 700;
  @media ${device.tablet} {
    font-size: 30px;
  }
  :hover {
    border-bottom: 2px solid #518084;
  }
`

const DropdownLink = styled(Link)`
  float: none;
  color: black;
  border: 2px solid white;
  padding: 10px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 12px;
  :hover {
    border: 2px solid #518084;
  }
`

const LogoContainer = styled.div`
  text-align: left;
  margin-right: 50px;
`
const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: right;
  align-items: center;
`

const DropdownContent = styled.div`
  display: none;
  position: fixed;
  align-content: flex-end;
  background-color: #f9f9f9;
  width: 30%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  ${Dropdown}:hover & {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @media ${device.tablet} {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      width: 90%;
    }
  }
  @media ${device.tablet} {
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
  }
`

const MenuBar = props => {
  return (
    <>
      <NavBar>
        <MenuContainer>
          <LogoContainer>
            <Menulink to="/">
              <LogoImage imgName={"Tallinnaan.fi"} />
            </Menulink>
          </LogoContainer>
          <Dropdown>
            <Menulink to="/risteilyt/">
              <DropdownButton>Risteilyt</DropdownButton>
            </Menulink>
            <DropdownContent>
              <DropdownLink to={"/risteilyt/22h/"}>22h risteily</DropdownLink>
              <DropdownLink to={"/risteilyt/paivaristeily/"}>
                Päivämatka Tallinnaan
              </DropdownLink>
              <DropdownLink to={"/risteilyt/mini/"}>
                Miniristeily Tallinnaan
              </DropdownLink>
            </DropdownContent>
          </Dropdown>
          <Dropdown>
            <Menulink to={"/reittimatkat/"}>
              <DropdownButton>Reittimatkat</DropdownButton>
            </Menulink>
            <DropdownContent>
              <DropdownLink to={"/reittimatkat/auto/"}>
                Autolla Tallinnaan
              </DropdownLink>
              <DropdownLink to={"/reittimatkat/lautat/"}>
                Helsinki-Tallinna lautta
              </DropdownLink>
            </DropdownContent>
          </Dropdown>
          <Dropdown>
            <Menulink to="/hotellimatka/">
              <DropdownButton>Hotellimatkat</DropdownButton>
            </Menulink>
            <DropdownContent>
              <DropdownLink to={"/hotellimatka/1-yo/"}>1 yö</DropdownLink>
              <DropdownLink to={"/hotellimatka/2-yota/"}>2 yötä</DropdownLink>
              <DropdownLink to={"/hotellimatka/3-yota/"}>3 yötä</DropdownLink>
            </DropdownContent>
          </Dropdown>
          <Menulink to={"/kylpylaloma/"}>
            <DropdownButton>Kylpylämatkat</DropdownButton>
          </Menulink>
          <Menulink to={"/akkilahdot/"}>
            <DropdownButton>Äkkilähdöt</DropdownButton>
          </Menulink>
          <Menulink to={"/majoitus/"}>
            <DropdownButton>Majoitus</DropdownButton>
          </Menulink>
          <Dropdown>
            <Menulink to="/muut/">
              <DropdownButton>Muut matkat</DropdownButton>
            </Menulink>
            <DropdownContent>
              <DropdownLink to={"/muut/parnun-matkat/"}>
                Pärnun matkat
              </DropdownLink>
              <DropdownLink to={"/muut/pietarin-risteily/"}>
                Pietarin risteily
              </DropdownLink>
              <DropdownLink to={"/muut/piknik-risteily/"}>
                Piknik risteily
              </DropdownLink>
              <DropdownLink to={"/muut/riikan-risteily/"}>
                Riikan risteily
              </DropdownLink>
              <DropdownLink to={"/muut/ruotsin-risteily/"}>
                Tukholman matkat
              </DropdownLink>
              <DropdownLink to={"/muut/romanttinen-viikonloppu/"}>
                Romanttinen viikonloppu
              </DropdownLink>
            </DropdownContent>
          </Dropdown>
          <Menulink to={"/blogi/"}>
            <DropdownButton>Blogi</DropdownButton>
          </Menulink>
        </MenuContainer>
      </NavBar>

      <NavBarMobile>
        <Link to="/">
          <LogoImage imgName={"Tallinnaan.fi"} />
        </Link>
        <Dropdown>
          <DropdownButton>
            <Icon />
          </DropdownButton>
          <DropdownContent>
            <LogoContainer>
              <Link to="/">
                <LogoImage imgName={"Tallinnaan.fi"} />
              </Link>
            </LogoContainer>
            <DropdownLink to={"/"}>Tallinnan matkat</DropdownLink>
            <DropdownLink to={"/risteilyt/"}>Risteilyt</DropdownLink>
            <DropdownLink to={"/risteilyt/22h/"}>22h risteily</DropdownLink>
            <DropdownLink to={"/risteilyt/paivaristeily/"}>
              Päivämatka Tallinnaan
            </DropdownLink>
            <DropdownLink to={"/risteilyt/mini/"}>
              Miniristeily Tallinnaan
            </DropdownLink>
            <DropdownLink to={"/reittimatkat/"}>
              Reittimatkat Tallinnaan
            </DropdownLink>
            <DropdownLink to={"/reittimatkat/auto/"}>
              Autolla Tallinnaan
            </DropdownLink>
            <DropdownLink to={"/reittimatkat/lautat/"}>
              Helsinki-Tallinna lautta
            </DropdownLink>
            <DropdownLink to={"/hotellimatka/"}>Hotellimatkat</DropdownLink>
            <DropdownLink to={"/hotellimatka/1-yo/"}>1 yö</DropdownLink>
            <DropdownLink to={"/hotellimatka/2-yota/"}>2 yötä</DropdownLink>
            <DropdownLink to={"/hotellimatka/3-yota/"}>3 yötä</DropdownLink>
            <DropdownLink to={"/kylpylaloma/"}>Kylpyläloma</DropdownLink>
            <DropdownLink to={"/akkilahdot/"}>Äkkilähdöt</DropdownLink>
            <DropdownLink to={"/majoitus/"}>Majoitus</DropdownLink>
            <DropdownLink to={"/muut/"}>Muut matkat</DropdownLink>
            <DropdownLink to={"/muut/parnun-matkat/"}>
              Pärnun matkat
            </DropdownLink>
            <DropdownLink to={"/muut/pietarin-risteily/"}>
              Pietarin risteily
            </DropdownLink>
            <DropdownLink to={"/muut/piknik-risteily/"}>
              Piknik risteily
            </DropdownLink>
            <DropdownLink to={"/muut/riikan-risteily/"}>
              Riikan risteily
            </DropdownLink>
            <DropdownLink to={"/muut/ruotsin-risteily/"}>
              Tukholman matkat
            </DropdownLink>
            <DropdownLink to={"/muut/romanttinen-viikonloppu/"}>
              Romanttinen viikonloppu
            </DropdownLink>
            <DropdownLink to={"/blogi/"}>Blogi</DropdownLink>
          </DropdownContent>
        </Dropdown>
      </NavBarMobile>
    </>
  )
}

export default MenuBar
